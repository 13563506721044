import React from 'react';
import Welcome from './welcome';
import AboutUs from './aboutus';
import Benefits from './benefits';
import ApplicationInfo from './applicationinfo';
import FormScreen from './form';
import Footer from './footer';

const App = () => (
  <>
    <Welcome />
    <AboutUs />
    <Benefits />
    <ApplicationInfo />
    <FormScreen />
    <Footer />
  </>
);

export default App;
