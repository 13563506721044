import React from 'react';
import Position from '../../assets/footer/position.png';
import Email from '../../assets/footer/email.png';
import Logo from '../../assets/footer/logo.png';
import './style.scss';

const Footer = () => (
  <footer className="footer">
    <section>
      <a target="_new" href="https://goo.gl/maps/ZuWinv82m2RAXNFx7">
        <article className="links-container">
          <img src={Position} alt="position" />
          <p>
            <span>Route 64 Logistics</span>
            <br />
            Orange County,
            <br />
            California, United States
          </p>
        </article>
      </a>
      <a href="mailto:careers@route64logistics.com">
        <article className="links-container">
          <img src={Email} alt="position" />
          <p>careers@route64logistics.com</p>
        </article>
      </a>
    </section>
    <div>
      <p>
        2020, ROUTE64  powered by
      </p>
      <a target="_new" href="https://cyberhelpsolutions.com/">
        <img src={Logo} alt="logo" />
      </a>
    </div>
  </footer>
);

export default Footer;
