import React from 'react';
import Rate from '../../assets/screen_3/rate.png';
import Health from '../../assets/screen_3/health.png';
import Paid from '../../assets/screen_3/paid.png';
import Referral from '../../assets/screen_3/referral.png';
import Tuition from '../../assets/screen_3/tuition.png';
import Time from '../../assets/screen_3/time.png';
import './style.scss';

const Benefits = () => (
  <section className="benefits">
    <h3>Our Benefits</h3>
    <div>
      <article>
        <img src={Rate} alt="rate" />
        <h5>Starting rate $16.5 per hour</h5>
      </article>
      <article>
        <img src={Health} alt="rate" />
        <h5>Health benefits package</h5>
      </article>
      <article>
        <img src={Paid} alt="rate" />
        <h5>Paid performance incentives</h5>
      </article>
      <article>
        <img src={Referral} alt="rate" />
        <h5>Paid referral program</h5>
      </article>
      <article>
        <img src={Tuition} alt="rate" />
        <h5>Tuition reimbursement</h5>
      </article>
      <article>
        <img src={Time} alt="rate" />
        <h5>Paid time off</h5>
      </article>
    </div>
  </section>
);

export default Benefits;
