import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const MainBtn = ({ action }) => {
  const btn = useRef(null);
  const mainAction = async event => {
    event.preventDefault();
    btn.current.disabled = true;
    await action();
    btn.current.disabled = false;
  };

  return (
    <button
      type="button"
      onClick={mainAction}
      ref={btn}
    >
      Join Us!
    </button>
  );
};

MainBtn.propTypes = {
  action: PropTypes.func.isRequired,
};

export default MainBtn;
