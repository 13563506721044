import React from 'react';
import Car from '../../assets/screen_2/car.png';
import './style.scss';

const AboutUs = () => (
  <section className="about-us">
    <article>
      <h4>About us</h4>
      <p>
        We are a delivery company partnering with Amazon,
        dedicated to the satisfaction of our customers.
      </p>
      <p className="bold">Join our drivers team!</p>
    </article>
    <img src={Car} alt="car" />
  </section>
);

export default AboutUs;
