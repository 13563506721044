import React from 'react';
import Info from '../../assets/screen_4/info.png';
import './style.scss';

const ApplicationInfo = () => (
  <section className="application-info">
    <img src={Info} alt="info" />
    <div>
      <article>
        <h4>Requirements</h4>
        <ul>
          <li>Must be 21+ years older.</li>
          <li>Must have a valid driver&rsquo;s license.</li>
          <li>Previous driving  and delivery experience preferred.</li>
          <li>
            Ability to handle physical workload (moving in and out
            of a van up to 250 times).
          </li>
        </ul>
      </article>
      <article>
        <h4>Responsibilities</h4>
        <ul>
          <li>Deliver products to customer locations in a timely manner.</li>
          <li>Safely drive and operate your delivery vehicle at all times.</li>
          <li>Navigate a variety of routes throughout delivery area.</li>
          <li>Lift packages up to 50 lbs.</li>
          <li>Provide an excellent customer service.</li>
        </ul>
      </article>
    </div>
  </section>
);

export default ApplicationInfo;
