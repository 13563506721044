import React from 'react';
import Logo from '../../assets/screen_1/logo.png';
import MainBtn from '../shared/MainBtn';
import './style.scss';

const Welcome = () => {
  const btnAction = () => {
    document.getElementById('form-section')
      .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="welcome">
      <header>
        <img src={Logo} alt="logo" />
        <a target="_new" href="hhttps://goo.gl/maps/ZuWinv82m2RAXNFx7">
          <h2>Orange County, CA</h2>
        </a>
      </header>
      <article>
        <div>
          <h1>
            We are looking for
          </h1>
          <h1>
            drivers like you!
          </h1>
          <MainBtn action={btnAction} />
        </div>
      </article>

    </section>
  );
};

export default Welcome;
